import { A, useNavigate } from "@solidjs/router";
import { createSignal, Show } from "solid-js";
import {
  editIcon,
  flipIcon,
  novaChatBubbleBtm,
  plutoGiftBtmRight,
} from "~/assets/assets";
import { GiftCardFront } from "./gift_card_front";
import { GiftCardBack } from "./gift_card_back";
import { GiftCardDetails } from "~/server/types/pluto";
import { getShareableGiftLink } from "~/server/apis/client_apis";
import { ThreeDotLoader } from "~/widgets/button";

export interface GiftCardProps {
  giftDetails: () => GiftCardDetails;
  isEditable?: boolean;
  flipOnCard?: boolean;
  flipOnButton?: boolean;
  showActions?: boolean;
  showActionsBg?: boolean;
  editPath?: string;
  showHeader?: boolean;
  generatedOnDate?: string;
  budget?: string;
  className?: string;
}

export function GiftCard({
  giftDetails,
  isEditable = false,
  flipOnCard = false,
  flipOnButton = true,
  showActions = true,
  showActionsBg = true,
  editPath,
  showHeader,
  generatedOnDate,
  budget,
  className = "",
}: GiftCardProps) {
  const navigate = useNavigate();

  const [isFlipped, setIsFlipped] = createSignal(false);
  const [isLoading, setIsLoading] = createSignal(false);

  const toggleFlip = () => setIsFlipped(!isFlipped());

  const handleEdit = () => {
    if (editPath) {
      navigate(editPath, {
        state: { side: isFlipped() ? "back" : "front" },
      });
    }
  };

  const getShareableLink = async () => {
    setIsLoading(true);
    try {
      const response = await getShareableGiftLink(giftDetails().id);
      if (response.link) {
        window.open(response.link, "_blank");
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const cardContent = (
    <div
      class={`relative h-[500px] w-[343px] rounded-3xl transition-transform 
                duration-500 [perspective:1000px] sm:w-[360px] ${className}`}
    >
      <div
        class={`relative h-full w-full transition-transform duration-500 
                 [transform-style:preserve-3d] ${isFlipped() ? "[transform:rotateY(180deg)]" : ""}`}
      >
        <div
          class="backface-hidden absolute h-full w-full rounded-3xl bg-plutoGiftCard"
          style={{ "backface-visibility": "hidden" }}
        >
          <img
            src={novaChatBubbleBtm}
            class="absolute bottom-0 left-0 rounded-bl-[20px]"
          />
          <img
            src={plutoGiftBtmRight}
            class="absolute bottom-0 right-0 rounded-br-[20px]"
          />
          <GiftCardFront
            giftDetails={giftDetails}
            isEditable={isEditable}
            onClick={flipOnCard ? toggleFlip : undefined}
          />
        </div>

        <div
          class="backface-hidden absolute h-full w-full rounded-3xl bg-plutoGiftCard [transform:rotateY(180deg)]"
          style={{ "backface-visibility": "hidden" }}
        >
          <img
            src={novaChatBubbleBtm}
            class="absolute bottom-0 left-0 rounded-bl-[20px]"
          />
          <img
            src={plutoGiftBtmRight}
            class="absolute bottom-0 right-0 rounded-br-[20px]"
          />
          <GiftCardBack
            giftDetails={giftDetails}
            isEditable={isEditable}
            onClick={flipOnCard ? toggleFlip : undefined}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Show when={showActions} fallback={cardContent}>
      <div
        class="mb-8 w-[375px] rounded-[40px] p-4"
        classList={{ "bg-[#151515]": showActionsBg }}
      >
        <Show when={showHeader}>
          <div class="mb-2 flex items-center gap-2 px-1">
            <p class="text-nowrap text-smallBold uppercase tracking-[1.1px]">
              <Show when={generatedOnDate}>{generatedOnDate}</Show>{" "}
              <Show when={budget}>· Worth ₹{budget}</Show>
            </p>
            <hr class="w-full border border-t border-basePrimaryDark" />
          </div>
        </Show>
        {cardContent}

        <div class="my-2 flex justify-around">
          <Show when={flipOnButton}>
            <button
              class="flex items-center justify-center gap-1 py-1.5"
              onClick={toggleFlip}
            >
              <img src={flipIcon} alt="flip icon" />
              <p class="text-mediumBold text-white">
                {isFlipped() ? "Front side" : "Back side"}
              </p>
            </button>
          </Show>
          <Show when={editPath && giftDetails().paymentStatus !== "PAID"}>
            <button
              class="flex items-center justify-center gap-1 py-1.5"
              onClick={handleEdit}
            >
              <img src={editIcon} alt="edit icon" />
              <p class="text-mediumBold text-white">Edit</p>
            </button>
          </Show>
        </div>

        <div class="flex gap-3">
          <button class="flex h-[44px] w-[165px] items-center justify-center rounded-[41px] border border-baseSecondaryDark px-4">
            <p class="text-buttonMedium text-white">Receiver's preview</p>
          </button>
          <Show
            when={giftDetails().paymentStatus === "PAID"}
            fallback={
              <A
                href={`/gifts/${giftDetails().id}/disclaimer`}
                class="flex h-[44px] w-[165px] items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
              >
                <p class="text-buttonMedium font-bold text-textDark">
                  Next: Top up gift
                </p>
              </A>
            }
          >
            <button
              onClick={getShareableLink}
              class="flex h-[44px] w-[165px] items-center justify-center rounded-[41px] bg-baseTertiaryMedium px-3"
            >
              {isLoading() ? (
                <ThreeDotLoader color="#000" />
              ) : (
                <p class="text-buttonMedium font-bold text-textDark">Share</p>
              )}
            </button>
          </Show>
        </div>
      </div>
    </Show>
  );
}
